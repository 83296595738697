const teamNames = {
  rc: "RC Super Stars, Hubballi",
  mubarak: "Mubarak Boys, Mnagaluru",
  ms_dhoni: "MS Dhoni Gangavathi, Koppala",
  sailions: "Sai Lions, Bangalore",
  spartans: "Spartans Fighters, Belagavi",
  sachin: "Sachin legends, Sindhanur",
  jaibheem: "Jai Bheem Warriors, Uttara Karnataka",
  prathik: "Prathik Eleven, Sindhanur",
};

export { teamNames };
