const teams = {
  rc: [
    {
      name: "ARJUN BHOSALE",
    },
    {
      name: "IMRAN KOTESHWAR",
    },
    {
      name: "RAJESH POOJARI",
    },
    {
      name: "SANDEEP NAIK",
    },
    {
      name: "CHETAN NAIK",
    },
    {
      name: "NAGARAJ MESTA",
    },
    {
      name: "VEERESH SAVADI",
    },
    {
      name: "SANTOSH LEFTY",
    },
    {
      name: "HANUMANT MANGLI",
    },
    {
      name: "SAHILMOMIN",
    },
    {
      name: "IRSHAD KATTIMANI",
    },
    {
      name: "MUDDASSIR NAZAR",
    },
    {
      name: "VINOD KURUBAGATTI",
    },
    {
      name: "PRASHANT MUTGEKAR",
    },
    {
      name: "SANJU SHIROLAKOPPA",
    },
  ],
  mubarak: [
    {
      name: "ALI",
    },
    {
      name: "ANEES YALIGAR",
    },
    {
      name: "ASHOK MENDIS",
    },
    {
      name: "GULAM RASOOL",
    },
    {
      name: "KALANDAR NADAF",
    },
    {
      name: "PUNDLIK KARADI",
    },
    {
      name: "SADEEP KATARE",
    },
    {
      name: "SALIM YALIWAL",
    },
    {
      name: "SHIVAPPA MADAR",
    },
    {
      name: "SHIVU",
    },
    {
      name: "SHOAIB QURESHI",
    },
    {
      name: "SHRIKRISHNA",
    },
    {
      name: "UMAR AMMU",
    },
    {
      name: "WASIM PENDARI",
    },
    {
      name: "WASIM VADDO",
    },
  ],
  ms_dhoni: [
    {
      name: "DB BASAVA",
    },
    {
      name: "JP KORI",
    },
    {
      name: "KRISHNA",
    },
    {
      name: "MATIN MATT",
    },
    {
      name: "MOHAMMAD NAFEEZ",
    },
    {
      name: "MUTTU NAYAK",
      photo: "",
    },
    {
      name: "NAVEEN GOUDA",
      photo: "",
    },
    {
      name: "PUTTU",
      photo: "",
    },
    {
      name: "RAMU",
      photo: "",
    },
    {
      name: "SANDEEP PUNJALI",
      photo: "",
    },
    {
      name: "SANDEEP SANDY",
      photo: "",
    },
    {
      name: "SANTOSH",
      photo: "",
    },
    {
      name: "SATEESH",
      photo: "",
    },
    {
      name: "Sunil Kumar",
      photo: "",
    },
    {
      name: "YARI SWAMY",
      photo: "",
    },
  ],
  sailions: [
    {
      name: "Akash",
    },
    {
      name: "Arshad Dafedar",
    },
    {
      name: "Gouse",
    },
    {
      name: "Illu",
    },
    {
      name: "Imran",
    },
    {
      name: "Iranna",
    },
    {
      name: "mushahid",
    },
    {
      name: "Nagaraj",
    },
    {
      name: "Prashanth",
    },
    {
      name: "Sadiq",
    },
    {
      name: "Sayed H",
    },
    {
      name: "Shivnarayan",
    },
    {
      name: "Kiran Taralekar",
    },
    {
      name: "Saleem",
    },
    {
      name: "",
    },
  ],
  spartans: [
    {
      name: "Abu Bakar",
    },
    {
      name: "Anil",
    },
    {
      name: "Arif",
    },
    {
      name: "Irfan",
    },
    {
      name: "jayaram",
    },
    {
      name: "Kareem",
    },
    {
      name: "Mahantesh",
    },
    {
      name: "Manoj",
    },
    {
      name: "Prasanth Kumar",
    },
    {
      name: "Rafeeq talikoti",
    },
    {
      name: "Rajshekar",
    },
    {
      name: "Sadiq",
    },
    {
      name: "Saleem",
    },
    {
      name: "Vinay",
    },
    {
      name: "",
    },
  ],
  sachin: [
    {
      name: "anoop",
    },
    {
      name: "Domic",
    },
    {
      name: "Karthik",
    },
    {
      name: "Raghu",
    },
    {
      name: "Rajesh",
    },
    {
      name: "Riyaz",
    },
    {
      name: "Vijay",
    },
    {
      name: "Yogesh",
    },
    {
      name: "Ashok Chakri",
    },
    {
      name: "Prathap",
    },
    {
      name: "Vishwa",
    },
    {
      name: "Altaf",
    },
    {
      name: "vasanth",
    },
    {
      name: "Varun kumar",
    },
    {
      name: "Wajid",
    },
  ],
  jaibheem: [
    {
      name: "Narendra",
    },
    {
      name: "Dasharat",
    },
    {
      name: "Mithun",
    },
    {
      name: "Tousif",
    },
    {
      name: "Ibrahim",
    },
    {
      name: "Shafiq",
    },
    {
      name: "matin",
    },
    {
      name: "saddam",
    },
    {
      name: "Sameer",
    },
    {
      name: "Shreyas",
    },
    {
      name: "Rabbani",
    },
    {
      name: "Abishek",
    },
    {
      name: "Akshay",
    },
    {
      name: "Raju",
    },
    {
      name: "Abdul",
    },
  ],
  prathik: [
    {
      name: "Dhanu",
    },
    {
      name: "Ganesh",
    },
    {
      name: "ramesh",
    },
    {
      name: "Mohamad Jack",
    },
    {
      name: "Anand Mukri",
    },
    {
      name: "laxman",
    },
    {
      name: "shabuddin",
    },
    {
      name: "Vijay",
    },
    {
      name: "Nawaz",
    },
    {
      name: "Sanju power",
    },
    {
      name: "izar",
    },
    {
      name: "Manappa",
    },
    {
      name: "Rang swamy",
    },
    {
      name: "shan",
    },
    {
      name: "",
    },
  ],
};
let newObj = [];
Object.keys(teams).forEach((team) => {
  teams[team].forEach((player) => {
    const temp = {};
    temp["photo"] =
      "https://firebasestorage.googleapis.com/v0/b/kplcricket-d5078.appspot.com/o/teamDetails%2F" +
      team +
      "%2F" +
      player.name +
      ".jpg?alt=media";
    temp["name"] = player.name;
    newObj.push(temp);
  });
  teams[team] = newObj;
  newObj = [];
});

export { teams };
